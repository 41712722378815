import { MouseEvent, useEffect } from "react"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { LazyHydrateContainer } from "@/hoc/LazyHydrate"
import { Container, ContentContainer, Row } from "@/styles/utils/StyledGrid"
import { NextPageWithLayout } from "@/types"
import { Button } from "@/ui/Button/Button"
import { ButtonGroup } from "@/ui/Button/StyledButton"
import { Typography, Typography404 } from "@/ui/Typography/Typography"
import { GTAG_EVENTS } from "@/utils/constants"
import { gtagSend } from "@/utils/common/helpers"

const NotFoundPageRecommends = dynamic(() =>
  import("@/components/LeadHit").then(
    ({ NotFoundPageRecommends }) => NotFoundPageRecommends,
  ),
)

const Custom404: NextPageWithLayout = () => {
  const router = useRouter()

  const backHandle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    router.back()
  }

  useEffect(() => {
    gtagSend({
      event: GTAG_EVENTS.PAGE_404,
    })
  }, [])

  return (
    <>
      <Container variantWidth={"784px"}>
        <Row>
          <ContentContainer>
            <Typography404>404</Typography404>
            <Typography variant={"h1"}>Cтраница не&nbsp;найдена</Typography>
            <Typography>
              Что-то пошло не&nbsp;так — страница, которую вы хотели открыть,
              не&nbsp;существует.
            </Typography>
            <ButtonGroup
              style={{
                marginTop: "40px",
              }}
            >
              <Button variant={"filled"} as={"a"} href={"/"} size={"large"}>
                Перейти на&nbsp;главную
              </Button>
              <Button
                variant={"link"}
                as={"a"}
                size={"large"}
                isHiddenBg
                onClick={backHandle}
              >
                Вернуться назад
              </Button>
            </ButtonGroup>
            <br />
          </ContentContainer>
        </Row>
      </Container>

      <LazyHydrateContainer>
        <NotFoundPageRecommends />
      </LazyHydrateContainer>
    </>
  )
}

Custom404.displayName = "Custom404"

export default Custom404
